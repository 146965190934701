#bus {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.header {
  color: #505050;
  text-shadow: 0.05em 0.05em rgba(172, 172, 172, 0.7);
  font-size: 1.6em;
  margin-top: 20px;
}
.aboutP {
  text-align: left;
  text-indent: 15px;
  margin-bottom: 10px;
  color: #505050;
}
.ios {
  background-image: url("./ios.png");
  background-size: cover;
  width: 100%;
  aspect-ratio: 3/2.6;
}
.android {
  background-image: url("./android.png");
  background-size: cover;
  width: 100%;
  max-width: 150px;
  aspect-ratio: 1/2;
}
