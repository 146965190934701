#clear {
  display: flex;
  background-color: #23f66d;
  color: white;
  text-align: center;
  text-decoration: none;
  width: 50%;
  justify-content: center;
  align-items: center;
  min-height: 50px;
}

#what {
  background-color: #127dc5;
  color: white;
  text-align: center;
  text-decoration: none;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  min-height: 50px;
}
.cardButton {
  min-height: 60px;
  min-width: 45px;
  margin-right: 5px;
  font-size: 1.5rem;
  font-weight: bold;
}

.mainDiv {
  max-width: 50vw;
}
/* @media screen and (max-width: 1200px) { */
.displayCardButton {
  min-height: 40px;
  min-width: 30px;
  margin-right: 5px;
  font-weight: bold;
  font-size: 0.9em;
  margin-left: -15px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  background-color: #ffffff;
}
.displayCardButtonEmpty {
  min-height: 40px;
  min-width: 30px;
  margin-right: 5px;
  font-weight: bold;
  font-size: 0.9em;
  margin-left: 0px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  background-color: #ffffff;
}
.mainDiv {
  max-width: 100%;
}
/* } */

@media screen and (max-height: 660px) {
  .hide {
    display: none;
  }
  .margtop {
    margin-top: 0;
  }
}
