@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.App {
  text-align: center;
  margin: 0;
  padding: 0;
}
* {
  font-family: "Inter", sans-serif;
}
