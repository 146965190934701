.ads {
  background-image: url("./adsnew.png");
  background-size: cover;
}
.logoAdd {
  background-image: url("./logoAdd.png");
  background-size: cover;
}
@media only screen and (max-width: 500px) {
  .flexrowcontainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    /* height: 60vh; */
  }
  .flexrowcontainer > .flexrowitem {
    /* flex: 1 1 33%; */
    /*grow | shrink | basis */
    /* display: flex;*/

    background-image: url("./bgCard.jpg");

    /* color: #fff;
    font-size: 1rem;

    height: 23vh;
    margin: 5px;
    max-width: 100px;
    text-align: center;
    align-items: center;
    justify-content: center;  */
  }

  .flexrowitem {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    width: 30%;
    aspect-ratio: 3/4.5;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 3px 3px 9px #616161;
    margin-right: 5px;
    margin-bottom: 15px;
    text-decoration: none;
  }

  .linkCard {
    color: #fff;
    font-size: 1rem;
    text-decoration: none;
  }
}
@media only screen and (max-width: 350px) {
  .flexrowcontainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    /* height: 60vh; */
  }
  .flexrowcontainer > .flexrowitem {
    /* flex: 1 1 28%; */
    /*grow | shrink | basis */
    /* display: flex; */

    background-image: url("./bgCard.jpg");
    /* color: #fff;
    font-size: 0.8rem;
    height: 25vh;
    margin: 5px;
    max-width: 80px;
    text-align: center;
    align-items: center;
    justify-content: center; */
  }

  .flexrowitem {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    width: 30%;
    aspect-ratio: 3/4.5;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 3px 3px 9px #616161;
    margin-right: 5px;
    margin-bottom: 15px;
    text-decoration: none;
  }
  .linkCard {
    color: #fff;
    font-size: 0.8rem;
    text-decoration: none;
  }
}
@media only screen and (min-width: 501px) {
  .flexrowcontainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .flexrowcontainer > .flexrowitem {
    /* flex: 1 1 28%; */
    /*grow | shrink | basis */
    /* display: flex; */

    background-image: url("./bgCard.jpg");
    /* color: #fff;
    font-size: 1rem;
    height: 30vh;
    margin: 5px;
    text-align: center;
    align-items: center;
    justify-content: center; */
  }

  .flexrowitem {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    width: 30%;
    aspect-ratio: 3/4.5;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 3px 3px 9px #616161;
    margin-right: 5px;
    margin-bottom: 15px;
    text-decoration: none;
  }
  .linkCard {
    color: #fff;
    font-size: 1rem;
    text-decoration: none;
  }
}

.cardscontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 60vh; */
}
