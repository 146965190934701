@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 80vh;
  width: 100%;
  max-width: 500px;
}

.header {
  color: #505050;
  text-shadow: 0.05em 0.05em rgba(172, 172, 172, 0.7);
  font-size: 1.6em;
  margin-top: 20px;
}
.aboutP {
  text-align: left;
  text-indent: 15px;
  margin-bottom: 10px;
  color: #505050;
}
.usdt {
  width: 30px;
  height: 30px;
  background-image: url("./usdt.png");
  background-size: cover;
}
.btc {
  width: 30px;
  height: 30px;
  background-image: url("./btc.png");
  background-size: cover;
}
.eth {
  width: 30px;
  height: 30px;
  background-image: url("./eth.png");
  background-size: cover;
}
.doge {
  width: 30px;
  height: 30px;
  background-image: url("./doge.png");
  background-size: cover;
}
.usdc {
  width: 30px;
  height: 30px;
  background-image: url("./usdc.png");
  background-size: cover;
}
.kas {
  width: 30px;
  height: 30px;
  background-image: url("./kas.png");
  background-size: cover;
}
